import React, { Component } from 'react'
import { Layout } from 'core/components'
import { AAnimate, ABanner } from 'shared/components/common'
import { ComingSoon } from 'shared/components/ComingSoon/ComingSoon'

type FaqProps = PageProps
interface FaqState {
  mounted: boolean
  value: string
  showing: boolean
}
class Faq extends Component<FaqProps, FaqState> {
  state = {
    mounted: false,
    value: 'boletos',
    showing: false
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ mounted: true })
    }, 0)
  }

  render() {
    const {
      pageContext: { locale }
    } = this.props
    const { mounted } = this.state

    return (
      <Layout locale={locale}>
        <AAnimate type="fadeIn" in={mounted}>
          <React.Fragment>
            <ABanner title="Preguntas frecuentes" titleClassName="big-white" titleSize={12} />
            <ComingSoon />
          </React.Fragment>
        </AAnimate>
      </Layout>
    )
  }
}

export default Faq
